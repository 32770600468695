import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter  } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from './components/template/Theme'
import history from './history'
import Layout from './components/layout'
// import './locales'

/**
 * Set enableMock(Default false) to true at configs/app.config.js 
 * If you wish to enable mock api
 */

function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter history={history}>
					<Theme>
						<Layout />
					</Theme>
				</BrowserRouter >
			</PersistGate>
		</Provider>
	)
}

export default App
