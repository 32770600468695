import { createSlice } from '@reduxjs/toolkit'

export const initialState = {user: {
    first_name: "",
    email: "",
    email_verified_at: "",
    city_id: 0,
    role: "",
    sex: "",
    birthdate: "",
    notification_enabled: false,
    verified: false,
    active: false,
    price: 0,
    psych_type: "",
    updated_at: "",
    created_at: "",
    id: 0,
    is_favorite: false,
}}

export const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
	},
})

export const { setUser } = userSlice.actions

export default userSlice.reducer